import { default as aboutyav6xrwvRdMeta } from "/data-dev/workspace/Front_yb-switcher-website/pages/about.vue?macro=true";
import { default as detailRlGR6c196lMeta } from "/data-dev/workspace/Front_yb-switcher-website/pages/case/detail.vue?macro=true";
import { default as listdbVZHOcKuEMeta } from "/data-dev/workspace/Front_yb-switcher-website/pages/case/list.vue?macro=true";
import { default as indexWbXrVr7zaSMeta } from "/data-dev/workspace/Front_yb-switcher-website/pages/index.vue?macro=true";
import { default as informationlzzSAWSLa8Meta } from "/data-dev/workspace/Front_yb-switcher-website/pages/information.vue?macro=true";
import { default as loginLphD0o7jvwMeta } from "/data-dev/workspace/Front_yb-switcher-website/pages/login.vue?macro=true";
import { default as materialiUi0UFvjOpMeta } from "/data-dev/workspace/Front_yb-switcher-website/pages/material.vue?macro=true";
import { default as healthJIYSc1rObUMeta } from "/data-dev/workspace/Front_yb-switcher-website/pages/solution/health.vue?macro=true";
import { default as playTVR616jScxMeta } from "/data-dev/workspace/Front_yb-switcher-website/pages/solution/play.vue?macro=true";
import { default as sciencegLrlIBl5rrMeta } from "/data-dev/workspace/Front_yb-switcher-website/pages/solution/science.vue?macro=true";
import { default as tranjMobXPebsRMeta } from "/data-dev/workspace/Front_yb-switcher-website/pages/solution/tran.vue?macro=true";
import { default as versionS91rs1zhVLMeta } from "/data-dev/workspace/Front_yb-switcher-website/pages/version.vue?macro=true";
import { default as detailml2LWfBBJBMeta } from "/data-dev/workspace/Front_yb-switcher-website/pages/whitepage/detail.vue?macro=true";
import { default as listAXcRVY7uHMMeta } from "/data-dev/workspace/Front_yb-switcher-website/pages/whitepage/list.vue?macro=true";
export default [
  {
    name: aboutyav6xrwvRdMeta?.name ?? "about",
    path: aboutyav6xrwvRdMeta?.path ?? "/about",
    meta: aboutyav6xrwvRdMeta || {},
    alias: aboutyav6xrwvRdMeta?.alias || [],
    redirect: aboutyav6xrwvRdMeta?.redirect || undefined,
    component: () => import("/data-dev/workspace/Front_yb-switcher-website/pages/about.vue").then(m => m.default || m)
  },
  {
    name: detailRlGR6c196lMeta?.name ?? "case-detail",
    path: detailRlGR6c196lMeta?.path ?? "/case/detail",
    meta: detailRlGR6c196lMeta || {},
    alias: detailRlGR6c196lMeta?.alias || [],
    redirect: detailRlGR6c196lMeta?.redirect || undefined,
    component: () => import("/data-dev/workspace/Front_yb-switcher-website/pages/case/detail.vue").then(m => m.default || m)
  },
  {
    name: listdbVZHOcKuEMeta?.name ?? "case-list",
    path: listdbVZHOcKuEMeta?.path ?? "/case/list",
    meta: listdbVZHOcKuEMeta || {},
    alias: listdbVZHOcKuEMeta?.alias || [],
    redirect: listdbVZHOcKuEMeta?.redirect || undefined,
    component: () => import("/data-dev/workspace/Front_yb-switcher-website/pages/case/list.vue").then(m => m.default || m)
  },
  {
    name: indexWbXrVr7zaSMeta?.name ?? "index",
    path: indexWbXrVr7zaSMeta?.path ?? "/",
    meta: indexWbXrVr7zaSMeta || {},
    alias: indexWbXrVr7zaSMeta?.alias || [],
    redirect: indexWbXrVr7zaSMeta?.redirect || undefined,
    component: () => import("/data-dev/workspace/Front_yb-switcher-website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: informationlzzSAWSLa8Meta?.name ?? "information",
    path: informationlzzSAWSLa8Meta?.path ?? "/information",
    meta: informationlzzSAWSLa8Meta || {},
    alias: informationlzzSAWSLa8Meta?.alias || [],
    redirect: informationlzzSAWSLa8Meta?.redirect || undefined,
    component: () => import("/data-dev/workspace/Front_yb-switcher-website/pages/information.vue").then(m => m.default || m)
  },
  {
    name: loginLphD0o7jvwMeta?.name ?? "login",
    path: loginLphD0o7jvwMeta?.path ?? "/login",
    meta: loginLphD0o7jvwMeta || {},
    alias: loginLphD0o7jvwMeta?.alias || [],
    redirect: loginLphD0o7jvwMeta?.redirect || undefined,
    component: () => import("/data-dev/workspace/Front_yb-switcher-website/pages/login.vue").then(m => m.default || m)
  },
  {
    name: materialiUi0UFvjOpMeta?.name ?? "material",
    path: materialiUi0UFvjOpMeta?.path ?? "/material",
    meta: materialiUi0UFvjOpMeta || {},
    alias: materialiUi0UFvjOpMeta?.alias || [],
    redirect: materialiUi0UFvjOpMeta?.redirect || undefined,
    component: () => import("/data-dev/workspace/Front_yb-switcher-website/pages/material.vue").then(m => m.default || m)
  },
  {
    name: healthJIYSc1rObUMeta?.name ?? "solution-health",
    path: healthJIYSc1rObUMeta?.path ?? "/solution/health",
    meta: healthJIYSc1rObUMeta || {},
    alias: healthJIYSc1rObUMeta?.alias || [],
    redirect: healthJIYSc1rObUMeta?.redirect || undefined,
    component: () => import("/data-dev/workspace/Front_yb-switcher-website/pages/solution/health.vue").then(m => m.default || m)
  },
  {
    name: playTVR616jScxMeta?.name ?? "solution-play",
    path: playTVR616jScxMeta?.path ?? "/solution/play",
    meta: playTVR616jScxMeta || {},
    alias: playTVR616jScxMeta?.alias || [],
    redirect: playTVR616jScxMeta?.redirect || undefined,
    component: () => import("/data-dev/workspace/Front_yb-switcher-website/pages/solution/play.vue").then(m => m.default || m)
  },
  {
    name: sciencegLrlIBl5rrMeta?.name ?? "solution-science",
    path: sciencegLrlIBl5rrMeta?.path ?? "/solution/science",
    meta: sciencegLrlIBl5rrMeta || {},
    alias: sciencegLrlIBl5rrMeta?.alias || [],
    redirect: sciencegLrlIBl5rrMeta?.redirect || undefined,
    component: () => import("/data-dev/workspace/Front_yb-switcher-website/pages/solution/science.vue").then(m => m.default || m)
  },
  {
    name: tranjMobXPebsRMeta?.name ?? "solution-tran",
    path: tranjMobXPebsRMeta?.path ?? "/solution/tran",
    meta: tranjMobXPebsRMeta || {},
    alias: tranjMobXPebsRMeta?.alias || [],
    redirect: tranjMobXPebsRMeta?.redirect || undefined,
    component: () => import("/data-dev/workspace/Front_yb-switcher-website/pages/solution/tran.vue").then(m => m.default || m)
  },
  {
    name: versionS91rs1zhVLMeta?.name ?? "version",
    path: versionS91rs1zhVLMeta?.path ?? "/version",
    meta: versionS91rs1zhVLMeta || {},
    alias: versionS91rs1zhVLMeta?.alias || [],
    redirect: versionS91rs1zhVLMeta?.redirect || undefined,
    component: () => import("/data-dev/workspace/Front_yb-switcher-website/pages/version.vue").then(m => m.default || m)
  },
  {
    name: detailml2LWfBBJBMeta?.name ?? "whitepage-detail",
    path: detailml2LWfBBJBMeta?.path ?? "/whitepage/detail",
    meta: detailml2LWfBBJBMeta || {},
    alias: detailml2LWfBBJBMeta?.alias || [],
    redirect: detailml2LWfBBJBMeta?.redirect || undefined,
    component: () => import("/data-dev/workspace/Front_yb-switcher-website/pages/whitepage/detail.vue").then(m => m.default || m)
  },
  {
    name: listAXcRVY7uHMMeta?.name ?? "whitepage-list",
    path: listAXcRVY7uHMMeta?.path ?? "/whitepage/list",
    meta: listAXcRVY7uHMMeta || {},
    alias: listAXcRVY7uHMMeta?.alias || [],
    redirect: listAXcRVY7uHMMeta?.redirect || undefined,
    component: () => import("/data-dev/workspace/Front_yb-switcher-website/pages/whitepage/list.vue").then(m => m.default || m)
  }
]