<!-- @format -->

<template>
  <NuxtLayout>
    <div>
      <NuxtLoadingIndicator />
      <NuxtPage />
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
useHead({
  title: "100Mix导播台，革命性的云端导播技术创造精彩视觉体验",
  meta: [
    {
      name: "description",
      content:
        "打造会议、直播、导播一体化场景，专业在线会议、企业直播、教育直播、医疗直播、电商直播、自媒体直播解决方案",
    },
    {
      name: "keywords",
      content:
        "企业直播,网络研讨会,云导播,医疗直播,视频会议、腾讯会议、电商直播、自媒体直播、直播伴侣、obs、VMIX、rtc",
    },
  ],
  link: [
    {
      rel: "icon",
      href: "/favicon.ico",
    },
  ],
  // titleTemplate: (productCategory) => {
  //   return productCategory ? `${productCategory}` : "100Mix导播台，革命性的云端导播技术创造精彩视觉体验";
  // },
});
</script>

<script lang="ts">
import { defineComponent } from "vue";
import { $cookie, $localStorage, removeLoginDataFn } from "@/utils/index";

definePageMeta({
  layout: false,
});

export default defineComponent({
  created() {
    this.pageOnShowFn();
    this.determineUserInfoFn();
  },
  methods: {
    pageOnShowFn() {
      const self = this;
      if (typeof window !== "undefined") {
        window.addEventListener("visibilitychange", function () {
          if (!document.hidden) {
            self.determineUserInfoFn();
          }
        });
      }
    },
    determineUserInfoFn() {
      let loginStateInfo = getUserInfo();
      // let loginState = '{"accessToken":"b1e54943-0655-417b-9cdd-1d162f87d90c","refreshToken":"7f8a64a2-632e-4910-9e48-bfea395d0f8a","tokenType":"bearer","expiresIn":86399,"expiresAt":1700906138430,"scopes":["all"]}'
      let loginState = null;
      const env = useRuntimeConfig().public.env;
      console.log("env: ", env);
      if (env === "production") {
        // 线上
        loginState = $cookie.getCookie("switcher_login_state");
      } else if (env === "test") {
        // 测试
        loginState = $cookie.getCookie("switcher_login_state_test");
      } else {
        // 开发
        if (process.client) {
          if (window.location.href.includes("localhost")) {
            // 如果本地起服务开发 cookie 没法储存
            loginState = $localStorage.getItem("switcher_login_state_dev");
          } else {
            loginState = $cookie.getCookie("switcher_login_state_dev");
          }
        }
      }

      if (loginState) {
        loginState = JSON.parse(decodeURIComponent(loginState));
        $localStorage.setItem("loginState", loginState);
        // 官网推出登录&&导播台重新登录的情况 需要重新获取用户信息
        this.getUserInfoFn(loginState);
      } else {
        // 官网登录&&导播台退出登录的情况 需要删除本地用户数据
        if (loginStateInfo) {
          removeLoginDataFn();
        }
      }
    },
    async getUserInfoFn(loginState: any) {
      await (this.$ybGuard as any)
        .setLoginState(loginState, true)
        .then((data: any) => {
          (this.$ybGuard as any).getUserInfo().then((res: any) => {
            console.log("了获取用户信息----", res);
            $localStorage.setItem("userInfoState", res);
          });
        });
    },
  },
});
</script>
