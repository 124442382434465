// @ts-ignore
import YbGuard from '@yb-authing/guard-vue3'

export default defineNuxtPlugin((nuxtApp) => {
    let runtimeConfig = useRuntimeConfig().public;
    nuxtApp.vueApp.use(YbGuard, {
        clientId: runtimeConfig.clientId,
        clientSecret: runtimeConfig.clientSecret,
        domain: runtimeConfig.domain,
        remember: true
    })
})